
import Vue from 'vue';
import { mapState } from 'vuex';
import { LicenseType } from '@/helpers/constants';
import JohariWindow from '@/components/analytics/charts/GradeVisualization/JohariWindow.vue';
import { JohariPoint } from '@/components/analytics/charts/GradeVisualization/types';
import errorHandler from '@/helpers/errorHandler';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesAnalyticsFeedbackJohariItem,
  V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion,
  V1EntitiesAnalyticsFeedbackAverageRate,
} from '@/services/api/tsxass';
import GradeInfoDialog from '@/components/analytics/charts/GradeVisualization/GradeInfoDialog.vue';
import JohariHelpDialog from '@/components/analytics/charts/GradeVisualization/JohariHelpDialog.vue';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';
import SelectExpertsGroup from './SelectExpertsGroup.vue';
import AverageRatingWidget from './AverageRatingWidget.vue';
import DevelopmentPlanProgress from './DevelopmentPlanProgress.vue';

type SelectedGroup = {
  id: number;
  name: string;
  withoutSelf: boolean;
};

const SELF_ASSESSMENT_NAME = 'Самооценка';

export default Vue.extend({
  name: 'GradeVisualizationWidget',

  components: {
    JohariWindow,
    SelectExpertsGroup,
    GradeInfoDialog,
    JohariHelpDialog,
    DevelopmentPlanProgress,
    NoFilteredDataStub,
    AverageRatingWidget,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
    hideExpertsMenuByLicense: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isJoharyEmpty: true,
      chartData: {
        values: [] as V1EntitiesAnalyticsFeedbackJohariItem[],
        settings: {
          minValue: 1,
          maxValue: 5,
          middleX: 7,
          middleY: 7,
        },
      },
      loading: false,
      ratingGroupSelected: null as null | SelectedGroup,
      selectedGroup: null as null | SelectedGroup,
      averageRatingData: null as null | V1EntitiesAnalyticsFeedbackAverageRate,
      loadingAverage: false,
      questions: [] as V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[],
      loadingQuestions: false,
      showGradeInfoDialog: false,
      selectedCoordinateId: null as null | string,
      showHelpDialog: false,
      belowAnonymityThreshold: false,
    };
  },

  computed: {
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    hasHideLicense(): Boolean {
      return this.licenses.some((name: string) => name === LicenseType.HIDE_EXPERT_GROUPS);
    },
    chartPoints(): JohariPoint[] {
      return this.chartData.values.map((dot) => ({
        id: dot.coordinateId,
        x: dot.self,
        y: dot.avg,
        title: String(dot.count),
      }));
    },
    selectedPointQuestions(): V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[] {
      return this.questions.filter((question) => question.coordinateId === this.selectedCoordinateId);
    },
    key(): string {
      return `${this.surveyId}-${this.userId}`;
    },
  },
  watch: {
    key() {
      this.loadAverageRatingData();
      this.loadChartData();
      this.loadQuestionsData();
    },
  },
  methods: {
    onPointClick(point: any) {
      this.showGradeInfoDialog = true;
      this.selectedCoordinateId = point.id;
    },
    onRatingGroupSelect(group: SelectedGroup) {
      if (group === this.ratingGroupSelected) {
        return;
      }
      this.ratingGroupSelected = group;
      this.loadAverageRatingData();
    },
    onGroupSelect(group: SelectedGroup) {
      if (group === this.selectedGroup) {
        return;
      }
      this.selectedGroup = group;
      this.loadChartData();
      this.loadQuestionsData();
    },
    async loadChartData() {
      try {
        this.loading = true;
        const {
          data: { items, settings, analyticsTemplateSettings },
        } = await tsxassApi.getV1AnalyticsFeedbackJohari(
          this.surveyId,
          this.selectedGroup?.id || undefined,
          this.userId || undefined,
        );
        this.isJoharyEmpty = items.length === 0
          || Boolean(this.selectedGroup?.withoutSelf)
          || this.selectedGroup?.name === SELF_ASSESSMENT_NAME;

        this.$emit('johary-empty', this.isJoharyEmpty);
        this.chartData = {
          values: items,
          settings: {
            ...settings,
            ...analyticsTemplateSettings,
          },
        };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async loadAverageRatingData() {
      try {
        this.loadingAverage = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackAverageRate(
          this.surveyId,
          this.ratingGroupSelected?.id || undefined,
          this.userId || undefined,
          this.ratingGroupSelected?.withoutSelf,
        );
        this.averageRatingData = data;
        this.$emit('average-count-empty', !data?.count);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loadingAverage = false;
      }
    },
    async loadQuestionsData() {
      try {
        this.loadingQuestions = true;
        const {
          data: { questions, state },
        } = await tsxassApi.getV1AnalyticsFeedbackJohariQuestions(
          this.surveyId,
          this.selectedGroup?.id || undefined,
          this.userId || undefined,
        );
        this.questions = questions || [];
        this.belowAnonymityThreshold = !state?.display && state?.belowAnonymityThreshold;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loadingQuestions = false;
      }
    },
  },
});
